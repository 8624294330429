<template>
  <el-card style="min-height: calc(100vh - 140px)">
    <div class="additem-title f14">
      <span class="ml-20">我的优惠券</span>
    </div>
    <div class="flex p30" style="flex-wrap: wrap">
      <div v-if="couponData.length==0">暂无优惠券</div>
      <div v-for="item in couponData" :key="item.id" class="ld-c t-c mt-30">
        <div class="title">{{item.title}}</div>
        <div class="used">{{item.used==0?'平台优惠券':item.used==1?'新人优惠券':item.used==2?"商品优惠券":""}}</div>
        <div class="type">{{item.type==1?'满减券':item.type==2?'代金券':item.type==3?'折扣券':''}}</div>
      </div>
    </div>
<div class="submit mt-30" @click="$router.go(-1)">返回</div>
  </el-card>
</template>
<script>
export default {
  created() {
    this.memberCoupon();
  },
  data() {
    return {
      couponData: []
    };
  },
  methods: {
    //用户已领取优惠券
    memberCoupon() {
      this.$request
        .get(`/client/coupon/memberCoupon?page=1&limit=100`)
        .then(res => {
          if (res.data.code == 200) {
            this.couponData = res.data.data;
          }
        });
    }
  }
};
</script>

<style scoped>
.additem-title {
  border-bottom: 1px solid #e6e6e6;
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  border-bottom: 3px solid #2962ff;
  padding: 10px;
}
.ld-c {
  width: 90px;
  height: 98px;
  background-image: url("../assets/images/yh1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid transparent;
  margin-right: 20px;
}
.ld-c .title {
  font-size: 10px;
  font-weight: 600;
  color: #9a4a30;
  margin-top: 10px;
  padding: 0;
  border-bottom:0;
}
.ld-c .type {
  font-size: 12px;
  font-weight: 600;
  color: #bc7e35;
  margin-top: 18px;
}
.ld-c .used {
  margin-top: 10px;
  font-size: 10px;
  color: #9a4a30;
}
.submit {
  width: 70px;
  height: 30px;
  opacity: 1;
  background: #2962ff;
  border-radius: 8px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-left: 40px;
}
</style>
